import React from "react";

const SuperlistaPrivacyPage = () => {
  return (
    <div style={{ padding: 20, fontFamily: "'Montserrat', sans-serif" }}>
      <h2>Privacy Policy</h2>
      <p>
        Nerina Serra built the Superlista app as a free app. 
        This SERVICE is provided by Nerina Serra at no cost and is intended for use as is.
      </p>
      <p>
        This page is used to inform website visitors regarding Superlista’s policies with the collection, use, and disclosure of 
        Personal Information if anyone decided to use Superlista’s Service.
      </p>
      <p>
        If you choose to use Superlista’s Service, then you agree to the collection and use of information in relation with this policy. 
        The Personal Information that Superlista’s collect are used for providing and improving the Service. 
        Superlista’s will not use or share your information with anyone except as described in this Privacy Policy.
      </p>

      {
        // <p>
        //   The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Superlista, 
        //   unless otherwise defined in this Privacy Policy.
        // </p>
      }

      <p><strong>Information Collection and Use</strong></p>
      <p>
        For a better experience while using our Service, Superlista’s may require you to provide us with certain personally identifiable 
        information, including but not limited to username and advertising-id. 
        The information that Superlista’s request is retained on your device and is not collected by Superlista’s in any way.
      </p>
      <p>
        The app does use third party services that may collect information used to identify you.
      </p>

      <p><strong>Log Data</strong></p>
      <p>
        Superlista’s want to inform you that whenever you use Superlista’s Service, in case of an error in the app Superlista’s collect data and 
        information (through third party products) on your phone called Log Data. This Log Data may include information such as 
        your devices’s Internet Protocol (“IP”) address, device name, operating system version, configuration of the app when utilising 
        Superlista’s Service, the time and date of your use of the Service, and other statistics.
      </p>

      <p><strong>Cookies</strong></p>
      <p>
        Cookies are files with small amount of data that is commonly used an anonymous unique identifier.
        These are sent to your browser from the website that you visit and are stored on your devices’s internal memory.
      </p>
      <p>
        This Services does not uses these “cookies” explicitly. However, the app may use third party code and libraries that 
        use “cookies” to collection information and to improve their services. You have the option to either accept or refuse 
        these cookies, and know when a cookie is being sent to your device.
      </p>

      <p><strong>Security</strong></p>
      <p>
        Superlista’s value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable 
        means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage 
        is 100% secure and reliable, and Superlista’s cannot guarantee its absolute security.
      </p>

      <p><strong>Changes to This Privacy Policy</strong></p>
      <p>
        Superlista’s may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. 
        Superlista’s will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, 
        after they are posted on this page.
      </p>

      <p><strong>Contact Us</strong></p>
      <p>
        If you have any questions or suggestions about Superlista’s Privacy Policy, do not hesitate to contact us.
      </p>
    </div>
  );
};

export default SuperlistaPrivacyPage;